<template>
  <div v-if="booking" class="booking-form">
    <v-row>
      <v-col cols="12" md="6">
        <v-form ref="form" class="booking-form__form">
          <v-row v-if="!!booking.deleted_at">
            <v-col cols="12" class="mb-0 pb-0">
              <v-alert
                dense
                outlined
                type="warning"
                color="orange darken-3"
                class="mb-0"
              >
                This booking request is <strong>archived</strong>.
              </v-alert>
            </v-col>
          </v-row>

          <h3 class="py-5 primary--text">{{ header }}</h3>
          <v-row>
            <v-col cols="12" md="6">
              <label class="text-field-label">Status</label>
              <SelectStatus
                flat
                solo
                hide-details="auto"
                class="mt-2"
                v-model="form.status"
                :list="statuses"
                :status="booking.status"
                :error-messages="form.$getError('status')"
                :loading="form.$busy"
                :disabled="form.$busy || isDisabled"
              />
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Updated At</label>
              <v-text-field
                v-model="booking.updatedDate"
                flat
                solo
                required
                readonly
                class="mt-2"
                placeholder="Updated At"
                hide-details="auto"
              />
            </v-col>
            <v-col v-if="isCalendarBooking" cols="4">
              <label class="text-field-label">Booking Date</label>
              <DatetimePicker
                v-model="form.booking_date"
                flat
                solo
                required
                class="mt-2"
                placeholder="Date"
                hide-details="auto"
                :error-messages="form.$getError('booking_date')"
                :loading="form.$busy"
                :disabled="form.$busy || isDisabled"
                type="date"
              />
            </v-col>
            <v-col v-if="isCalendarBooking" cols="8">
              <label class="text-field-label">Time Slots</label>
              <SelectTimeSlots
                flat
                solo
                required
                class="mt-2"
                v-model="form.time_slots"
                :loading="form.$busy"
                :multiple="canSelectMultipleTimeSlots"
                :error-messages="form.$getError('booking_date')"
                :bookableId="form.bookableId"
                :bookingDate="form.booking_date"
                @input="selectedTimeSlots"
              />
            </v-col>

            <v-col
              cols="12"
              :md="hasEndtime ? 6 : 12"
              v-if="!isCalendarBooking"
            >
              <label class="text-field-label">Start Date</label>
              <DatetimePicker
                v-model="form.datetime_start"
                flat
                solo
                required
                class="mt-2"
                placeholder="Date"
                hide-details="auto"
                :less-than="hasEndtime ? form.datetime_end : null"
                :error-messages="form.$getError('datetime_start')"
                :loading="form.$busy"
                :disabled="form.$busy || isDisabled"
              />
            </v-col>
            <v-col v-if="hasEndtime && !isCalendarBooking" cols="12" md="6">
              <label class="text-field-label">End Date</label>
              <DatetimePicker
                v-model="form.datetime_end"
                flat
                solo
                required
                class="mt-2"
                placeholder="Date"
                hide-details="auto"
                :greater-than="hasEndtime ? form.datetime_start : null"
                :error-messages="form.$getError('datetime_end')"
                :loading="form.$busy"
                :disabled="form.$busy || !hasEndtime || isDisabled"
              />
            </v-col>

            <v-col cols="12">
              <label class="text-field-label">Comments</label>
              <v-textarea
                flat
                solo
                required
                hide-details
                class="mt-2"
                placeholder="Comments"
                v-model="form.comments"
                :error-messages="form.$getError('comments')"
                :loading="form.$busy"
                :disabled="form.$busy || isDisabled"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" md="6">
        <div v-if="hasBookable && hasBuilding" class="booking-form__bookable">
          <h3 class="py-5 primary--text">Service & Space Information</h3>

          <v-row>
            <v-col cols="12">
              <label class="text-field-label">Title</label>
              <v-text-field
                v-model="bookable.title"
                readonly
                hide-details
                class="mt-2"
                placeholder="Title"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Type</label>
              <v-text-field
                v-model="booking.serviceType"
                readonly
                hide-details
                class="mt-2"
                placeholder="Type"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Building</label>
              <v-text-field
                v-model="building.name"
                readonly
                hide-details
                class="mt-2"
                placeholder="Building"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div v-if="hasUser" class="booking-form__user">
          <h3 class="py-5 primary--text">User Information</h3>

          <v-row>
            <v-col cols="12" md="8">
              <label class="text-field-label">Name</label>
              <v-text-field
                v-model="user.full_name"
                readonly
                hide-details
                class="mt-2"
                placeholder="Requester"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <label class="text-field-label">Property</label>
              <v-text-field
                v-model="user.property.name"
                readonly
                hide-details
                class="mt-2"
                placeholder="property"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="7">
              <label class="text-field-label">Email</label>
              <v-text-field
                v-model="user.email"
                readonly
                hide-details
                class="mt-2"
                placeholder="Email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <label class="text-field-label">Number</label>
              <v-text-field
                v-model="user.phone_number"
                readonly
                hide-details
                class="mt-2"
                placeholder="Phone number"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <div class="mt-4">
          <v-btn
            v-if="hasPermissions"
            type="submit"
            color="primary"
            class="mr-4 px-6"
            height="40px"
            width="100%"
            :loading="form.$busy"
            :disabled="isDisabled"
            @click="submit"
            >Update</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Booking Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectStatus from '@/components/fields/SelectStatus'
import DatetimePicker from '@/components/fields/DatetimePicker'
import STATUS from '@/utils/enums/BookingStatus'
import SelectTimeSlots from '@/components/fields/SelectTimeSlots.vue'
import isEmpty from 'lodash/isEmpty'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    SelectStatus,
    DatetimePicker,
    SelectTimeSlots,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    booking: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        building_id: '',
        status: '',
        datetime_start: '',
        datetime_end: '',
        comments: '',
        bookableId: '',
      }),
      isCalendarBooking: false,
      lisTimeSlots: [],
      canSelectMultipleTimeSlots: false,
    }
  },

  computed: {
    ...mapState({
      bookable: (state) => state.bookable.bookableDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return 'Booking Information'
    },

    statuses() {
      return STATUS
    },

    user() {
      return this.booking && this.booking.user ? this.booking.user : false
    },

    building() {
      return this.booking && this.booking.building
        ? this.booking.building
        : false
    },

    bookable() {
      return this.booking && this.booking.bookable
        ? this.booking.bookable
        : false
    },

    hasUser() {
      return !!this.user
    },

    hasBuilding() {
      return !!this.building
    },

    hasBookable() {
      return !!this.bookable
    },

    hasEndtime() {
      return this.hasBookable ? this.bookable.has_end_time : false
    },

    isDisabled() {
      return this.booking ? !!this.booking.deleted_at : false
    },

    isUpdate() {
      return !!this.booking
    },

    hasPermissions() {
      return validatePermissions([PERMISSION.BOOKINGS_UPDATE], this.permissions)
    },
  },

  watch: {
    booking(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      updateBooking: 'booking/updateBooking',
    }),

    initForm() {
      if (this.booking) {
        this.form.building_id = this.booking.building_id
        this.form.status = this.booking.status
        this.form.datetime_start = this.booking.datetime_start
        this.form.datetime_end = this.hasEndtime
          ? this.booking.datetime_end
          : null
        this.form.comments = this.booking.comments
        this.form.time_slots = this.mapTimeSlot(this.booking.time_slots)
        this.form.bookableId = this.booking.bookable_id
        this.form.booking_date = this.booking.booking_date
        this.isCalendarBooking =
          this.booking.bookable.booking_option === 'calendar'
        this.canSelectMultipleTimeSlots =
          this.booking.bookable.is_multiple_time_slot
      }
      console.log('==this.booking', this.booking)
    },

    selectedTimeSlots(timeSlots) {
      this.lisTimeSlots = timeSlots.sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      )
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      await this.updateBooking(this.getFormData())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('Booking details successfully updated!')
          this.$emit('updated-booking')
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormData() {
      let form = this.form.$data()
      if (this.isUpdate && this.booking) {
        form.id = this.booking.id
        form.user_id = this.user.id
        form.bookable_id = this.bookable.id
        form.booking_date = this.booking.booking_date
        form.time_slots = isEmpty(this.lisTimeSlots)
          ? this.booking.time_slots
          : this.lisTimeSlots
      }

      if (!this.hasEndtime) {
        form.datetime_end = null
      }

      return form
    },

    mapTimeSlot(timeSlot) {
      return timeSlot.map((time) => {
        return { start: time.start, end: time.end }
      })
    },
  },
}
</script>
<style lang="scss">
.booking-form {
  .v-textarea {
    textarea {
      min-height: 200px;
    }
  }
}
</style>
