<template>
  <v-select
    class="select-time-increment-option"
    chips
    required
    :value="selectedItem"
    :items="items"
    :placeholder="placeholder"
    :loading="loading"
    :disabled="disabled"
    item-text="text"
    item-value="value"
    v-bind="$attrs"
    @input="onChange"
    :multiple="multiple"
  />
</template>
<script>
/**
 * ==================================================================================
 * Select Time slots
 * ==================================================================================
 **/
import { mapActions } from 'vuex'
import { dateFormatYMDFunc, getStartAndEndTime } from '@/utils/date'

export default {
  props: {
    value: {
      type: [Array, Number],
      default: () => [],
    },

    bookableId: {
      type: [Number, String],
      default: null,
    },

    bookingDate: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: 'Select option',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      fetching: false,
      items: [],
    }
  },

  computed: {
    selectedItem() {
      const sortedValue = Array.isArray(this.value)
        ? [...this.value].sort((a, b) => new Date(a.start) - new Date(b.start))
        : [this.value]

      return this.multiple ? sortedValue : sortedValue[0]
    },
  },

  created() {
    this.fetch()
  },

  watch: {
    bookingDate(newValue, oldValue) {
      this.fetch()
    },
  },

  methods: {
    onChange(value) {
      this.$emit('input', value)
    },

    ...mapActions({
      getAvailableTimeSlots: 'booking/getAvailableTimeSlots',
    }),

    async fetch() {
      if (this.fetching || !this.bookingDate || !this.bookableId) return
      this.fetching = true

      const fd = new FormData()
      fd.append('bookable_id', this.bookableId)
      fd.append('date', dateFormatYMDFunc(this.bookingDate))

      await this.getAvailableTimeSlots(fd)
        .then((data) => {
          const timeSlotData = data.map((time) => {
            return {
              value: {
                start: time.start,
                end: time.end,
              },
              text: getStartAndEndTime(time),
            }
          })
          this.items = timeSlotData
          this.$emit('populated', this.items)
        })
        .finally(() => {
          this.fetching = false
        })
    },
  },
}
</script>
